/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-26",
    versionChannel: "nightly",
    buildDate: "2024-05-26T00:06:25.627Z",
    commitHash: "3c944e35c7432f592d9255c0632dfdd34134e2ee",
};
